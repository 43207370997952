import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { CART_TYPES } from 'store/types'
import Layout from 'components/Layout'
import SEO from 'components/seo'
import HeaderSection from './HeaderSection'
import PricingSection from './PricingSection'
import ClientSection from '../Home/ClientSection'
import { WrapperClient } from './styles'
import API, { TransactionURL } from 'commons/API'

function PricingPage({ location }) {
  const dispatch = useDispatch()

  // detect country code by ip
  useEffect(() => {
    const getCountryCurrency = async () => {
      try {
        const { data: ipUser } = await API('https://api.ipify.org/?format=json')
        const { data: dataLocation } = await API(`https://ipapi.co/${ipUser.ip}/json/`)

        dispatch({ type: CART_TYPES.SET_CURRENCY, currency: dataLocation.currency })
        sessionStorage.setItem('geoLocation', JSON.stringify(dataLocation))
      } catch (err) {
        console.log('--- error getting current location', err.message)
      }
    }

    const geoLocation = sessionStorage.getItem('geoLocation')
    if(!geoLocation) getCountryCurrency()
    else {
      const currency = JSON.parse(geoLocation).currency
      dispatch({ type: CART_TYPES.SET_CURRENCY, currency: currency })
    }

    (async function getCurrencies() {
      try {
        const fetchAPI = await API(TransactionURL.GetCurrencies)
        const { currencies } = await fetchAPI.data
        const constructCurrencies = currencies.map((currency) => {
          const countryCode = currency.name.toUpperCase()

          return {
            ...currency,
            countryCode,
            currency : currency.name,
            name     : `${currency.country} (${countryCode})`
          }
        })

        dispatch({ type: CART_TYPES.SET_CURRENCIES, currencies: constructCurrencies })
      } catch (err) {
        console.log('--- error get list currencies', err.message)
      }
    })()
  }, [dispatch])

  return (
    <>
      <SEO title='Pricing Plan' description='Set plan based on your needs!' />
      <Layout>
        <HeaderSection />
        <PricingSection location={ location } />
        <WrapperClient>
          <ClientSection />
        </WrapperClient>
      </Layout>
    </>
  )
}

export default PricingPage
