import React from 'react'
import { FormattedMessage } from 'utils/intl'
import { Wrapper, WrapperClients } from './styles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { 
  generateElementID, 
  CONTENT_TITLE_ID_PREFIX,
  CONTENT_DESCRIPTION_ID_PREFIX 
} from 'utils/generateElementID'

function ClientSection() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "home/clients" } }, sort: {order: ASC, fields: name}) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: CONSTRAINED
                quality: 10
                pngOptions: {quality: 10}
              )
            }
          }
        }
      }
    }
  `)

  function generateImageID(id) {
    switch(id){
      case 'bd260939-4c99-56e9-8257-051d55070f68':
        return 'mainpage-logopartner-prasetia'
      case '99394237-56c3-5fb6-b0ed-c875c022e857':
        return 'mainpage-logopartner-pertamina'
      case 'b3f7b4bc-afcf-5441-b466-ef84553ea5db':
        return 'mainpage-logopartner-softbank'
      case '838872f4-02b9-5d4e-a1ea-09355f6b42eb':
        return 'mainpage-logopartner-kddi'
      case '32000803-4f1b-5b69-9175-2b0e6fd53778':
        return 'mainpage-logopartner-mrtjakarta'
      case 'e9f1ead2-7838-5707-be28-98472d581446':
        return 'mainpage-logopartner-alamsutera'
      case '07dcab88-ae31-5e7e-832f-9b52dfc965b7':
        return 'mainpage-logopartner-kementriankeuangan'
      case '937b85cf-8de1-5bed-9ca2-134e18dc35df':
        return 'mainpage-logopartner-indosatoredoo'
      case '7778c52c-e5fa-550c-a005-379e02333feb':
        return 'mainpage-logopartner-telkomsel'
      case '00294509-6e09-5c63-b518-de6b406d2092':
        return 'mainpage-logopartner-telkomindonesia'
      case '3fb2fffc-fa92-573c-8bd7-61fc1c001b87':
        return 'mainpage-logopartner-kementriansekertariatri'
      case '4fe02a78-adf0-508c-957d-0d5211a357e7':
        return 'mainpage-logopartner-mayapadahospital'
      case '3a01d8f9-e1b5-568d-bba6-66c67137fec4':
        return 'mainpage-logopartner-jiep'
      case '05330856-ca30-5b31-8e54-d746d47bf4a8':
        return 'mainpage-logopartner-pemdajakarta'
      case '701459ba-8f72-5bfc-b92f-dc87774bddca':
        return 'mainpage-logopartner-mitsui&co.'
    }
  }

  return (
    <Wrapper>
      <h1 id="clients">
        <a id={ generateElementID(CONTENT_TITLE_ID_PREFIX, 'partner&client') } href="#clients" aria-label="highest permalink">
          <FormattedMessage id="255688931" />
        </a>
      </h1>
      <p id={ generateElementID(CONTENT_DESCRIPTION_ID_PREFIX, 'partner&client') }>
        <FormattedMessage id="422c01abc" />
      </p>
      <WrapperClients>
        { 
          data.allFile.edges.map((edge, i) => (
            <GatsbyImage
              id={ generateImageID(edge.node.id) }
              key={ i }
              image={ getImage(edge.node) }
              className='client__img-wrapper'
              imgClassName='client__img'
              alt='clients'
            />
          ))
        }
      </WrapperClients>
    </Wrapper>
  )
}

export default ClientSection
