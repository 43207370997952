import styled from 'styled-components'

import { paddingPage } from '../styles'
import { darkBlueGrey, paleGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  ${paddingPage}
  padding-top: 6em;
  background: #fff;

  > h1 {
    font-size: 4.167em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: ${darkBlueGrey};
    margin: 0;
    margin-bottom: 0.4em;

    a {
      color: ${darkBlueGrey};
      text-decoration: none;
    }

    ${IS_FRAME_MOBILE} {
      font-size: 2em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  > p {
    width: 47%;
    font-size: 1.2em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    margin: 0 auto;
    color: ${darkBlueGrey};

    ${IS_FRAME_TABLET} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.5em;
    }

    ${IS_FRAME_MOBILE} {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${IS_FRAME_TABLET} {
    padding: 4em 2em 0;
  }

  ${IS_FRAME_MOBILE} {
    padding: 1em 1em 0;
  }
`

export const WrapperClients = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  margin-top: 3em;

  .client__img-wrapper {
    background-color: ${paleGrey};
    height: 90px;
    width: -webkit-fill-available;
    padding: 30px;
  }

  .client__img {
    height: 90px;
    width: 80%;
    object-fit: contain !important;
    position: absolute;
    top: 50%;
    margin: auto;
    display: block;
    transform: translateY(-50%);
  }

  ${IS_FRAME_TABLET} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0;

    .client__img-wrapper {
      height: 60px;
      padding: 15px 10px;
    }

    .client__img {
      height: 60px;
      top: 60px;
    }
  }

  ${IS_FRAME_MOBILE} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 2em;

    .client__img-wrapper {
      height: 60px;
      padding: 15px 10px;
    }

    .client__img {
      height: 60px;
      top: 60px;
    }
  }
`
